import { graphql } from "gatsby";
import React from "react";
import { ContactUs } from "../ContactUs";
import "./styles.css";

const AboutUsAdvantages = ({ advantages, formHeader, formBody }) => {
  return (
    <ContactUs className="AboutUsAdvantages" advantages={advantages} formHeader={formHeader} formBody={formBody} />
  );
};

AboutUsAdvantages.getLivePreviewData = ({ data, getAsset }) => ({
  aboutUsAdvantages: {
    ...data.aboutUsAdvantages,
  },
});

const aboutUsAdvantagesQuery = graphql`
  fragment AboutUsAdvantagesFragment on MarkdownRemarkFrontmatter {
    aboutUsAdvantages {
      advantages {
        title
        text
        result
      }
      formHeader {
        title
        text
      }
    }
  }
`;

export { AboutUsAdvantages, aboutUsAdvantagesQuery };
