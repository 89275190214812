import { graphql } from "gatsby";
import React from "react";
import "./styles.css";

const AboutUsPurposes = ({ title, subtitle, notation }) => {
  return (
    <div className="AboutUsPurposes__background">
      <div className="Content">
        <div className="AboutUsPurposes__container">
          <div className=" [ AboutUsPurposes__title ] [ Font_smallHeaderCaps ] ">{title}</div>
          <div className="[ AboutUsPurposes__subtitle ] [ Font_mediumHeaderSerif ] ">{subtitle}</div>
          <div className=" [ AboutUsPurposes__notation ] [ Font_regularText ] ">{notation}</div>
        </div>
      </div>
    </div>
  );
};

AboutUsPurposes.getLivePreviewData = ({ data, getAsset }) => ({
  aboutUsPurposes: {
    ...data.aboutUsPurposes,
  },
});

const aboutUsPurposesQuery = graphql`
  fragment AboutUsPurposesFragment on MarkdownRemarkFrontmatter {
    aboutUsPurposes {
      title
      subtitle
      notation
    }
  }
`;

export { AboutUsPurposes, aboutUsPurposesQuery };
