import { graphql } from "gatsby";
import React from "react";
import { LinePartners } from "../LinePartners/LinePartners";
import { LogoHoney } from "../LogoHoney";
import { Hexagon } from "./images";
import "./styles.css";

const AboutUsHero = ({ title, whyUs, partners }) => {
  return (
    <div className="AboutUsHero">
      <div className="[ AboutUsHero__container ] [ Content ]">
        <div className="AboutUsHero__content">
          <div className="[ AboutUsHero__title ] [ Font_bigHeaderSerif ]">{title}</div>
          <div className="[ AboutUsHero__description ] [ Font_regularText ]">
            <Hexagon className="AboutUsHero__hexagon" />
            {whyUs}
          </div>
        </div>
        <LogoHoney className="AboutUsHero__logo" />
      </div>

      <LinePartners className="AboutUsHero__partners" partners={partners} />
    </div>
  );
};

AboutUsHero.getLivePreviewData = ({ data, getAsset }) => ({
  aboutUsHero: {
    ...data.aboutUsHero,
  },
});

const aboutUsHeroQuery = graphql`
  fragment AboutUsHeroFragment on MarkdownRemarkFrontmatter {
    aboutUsHero {
      title
      whyUs
    }
  }
`;

export { AboutUsHero, aboutUsHeroQuery };
