import React from "react";
import Helmet from "react-helmet";
import { AboutUsAdvantages } from "../components/AboutUsAdvantages";
import { AboutUsHero } from "../components/AboutUsHero";
import { AboutUsPurposes } from "../components/AboutUsPurposes";
import { Layout } from "../components/Layout";

const AboutUsPageTemplate = ({ layout, ...props }) => {
  return (
    <>
      <Helmet>
        <title>{props.seo.title}</title>
        <meta name="description" content={props.seo.description} />
        <meta name="keywords" content={props.seo.keywords} />
      </Helmet>

      <AboutUsHero partners={layout.partners} {...props.aboutUsHero} />
      <AboutUsPurposes {...props.aboutUsPurposes} />
      <AboutUsAdvantages formBody={layout.formBody} {...props.aboutUsAdvantages} />
    </>
  );
};

AboutUsPageTemplate.getLivePreviewData = props => ({
  ...Layout.getLivePreviewData(props),
  ...AboutUsHero.getLivePreviewData(props),
  ...AboutUsPurposes.getLivePreviewData(props),
  ...AboutUsAdvantages.getLivePreviewData(props),
});

const AboutUsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout {...data.layout.frontmatter}>
      <AboutUsPageTemplate layout={data.layout.frontmatter} {...frontmatter} />
    </Layout>
  );
};

const pageQuery = graphql`
  query AboutUsPageTemplate {
    layout: markdownRemark(frontmatter: { meta: { eq: "layout" } }) {
      frontmatter {
        ...LayoutFragment
        formBody {
          policy
          buttonLabel
          successMessage
          successButtonLabel
          errorMessage
          errorButtonLabel
        }
        partners {
          childImageSharp {
            fluid(maxWidth: 192) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }

    markdownRemark(frontmatter: { templateKey: { eq: "about-us" } }) {
      frontmatter {
        seo {
          title
          description
          keywords
        }

        ...AboutUsHeroFragment
        ...AboutUsPurposesFragment
        ...AboutUsAdvantagesFragment
      }
    }
  }
`;

export default AboutUsPage;

export { pageQuery, AboutUsPageTemplate };
